<template>
  <div>
    <azkar-category-form
      :item="azkarCategory"
      :submit="addZikr"
    />
  </div>
</template>
<script>
import AzkarCategoryForm from '@/common/components/azkar/AzkarCategoryForm.vue'
import AzkarCategory from '@/common/compositions/azkar/AzkarCategoryApi'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'AddAzkarCategory',
  components: { AzkarCategoryForm },
  setup() {
    const { azkarCategory, addazkarCategoryRequest, prepareFormData } = AzkarCategory()
    const { successfulOperationAlert } = handleAlerts()
    return {
      azkarCategory, addazkarCategoryRequest, prepareFormData, successfulOperationAlert,
    }
  },
  methods: {
    addZikr() {
      this.prepareFormData()
      return this.addazkarCategoryRequest().then(() => {
        this.successfulOperationAlert('Category is added successfully')
        this.$router.push({ name: 'azkarCategory-list' })
      })
    },
  },
}
</script>
<style lang="">

</style>
